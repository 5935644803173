<template>
  <div ref="setting" class="content-wrapper view-setting pt-2">
    <div class="content">
      <b-card>
        <h4><i class="fas fa-cog"></i> ตั้งค่า</h4>
        <hr />
        <b-form-group
          label="เชื่อมกับ Health-Link:"
          label-cols-sm="3"
          label-align-sm="right"
        >
          <b-form-checkbox
            class="mt-2"
            v-model="isFHIRAccessEnabled"
            switch
            @change="changeFHIR"
          ></b-form-checkbox>
        </b-form-group>
        <b-form
          ref="clinicSetting"
          id="clinicSetting"
          autocomplete="off"
          @submit.prevent="saveSetting"
        >
          <b-form-group
            label="ข้อมูลคลินิก"
            label-cols-lg="3"
            label-size="lg"
            label-class=" pt-0"
          >
            <b-form-group
              label="Logo คลินิก:"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <upload-avatar-form
                v-if="clinic.id"
                ref="UploadAvatar"
                class="d-inline-block"
                square
                border
                size="119px"
                :uploadMaxSize="180"
                :default-id="defaultContentId"
                @callback="uploadAvatarCallback"
              ></upload-avatar-form>
            </b-form-group>
            <b-form-group
              label="ชื่อคลินิก:"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                v-model.trim="clinic.name"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="ชื่อคลินิก(อังกฤษ):"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                v-model.trim="clinic.nameEn"
                type="text"
              ></b-form-input>
            </b-form-group>
            <!-- 
            <b-form-group
              label="Code : "
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="inputCode"
                v-model="clinic.code"
                type="text"
                placeholder="code"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="รายละเอียดคลีนิค"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="inputDetail"
                v-model="clinic.detail"
                type="text"
                placeholder="รายละเอียดคลีนิค"
              ></b-form-input>
            </b-form-group> -->

            <b-button class="float-right" type="submit" variant="primary"
              >บันทึก</b-button
            >
          </b-form-group>
        </b-form>
        <hr />
        <b-form-group
          label="ข้อมูลติดต่อสาขา"
          label-cols-lg="3"
          label-size="lg"
          label-class=" pt-0"
        >
          <b-form-group
            label="ชื่อสาขา:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="branchTitle"></b-form-input>
          </b-form-group>
          <b-form-group
            label="ที่อยู่:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-textarea
              v-model="address"
              placeholder="..."
              rows="2"
              max-rows="2"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            label="ชื่อสาขา(อังกฤษ):"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="branchTitleEn"></b-form-input>
          </b-form-group>
          <b-form-group
            label="ที่อยู่(อังกฤษ):"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-textarea
              v-model="addressEn"
              placeholder="..."
              rows="2"
              max-rows="2"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            label="เบอร์โทร:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="telNumber"></b-form-input>
          </b-form-group>

          <b-form-group
            label="facebook:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="facebook"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Line ID:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="lineID"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Website:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-form-input v-model="website"></b-form-input>
          </b-form-group>
          <b-form-group label="hcode:" label-cols-sm="3" label-align-sm="right">
            <b-form-input disabled v-model="hcode"></b-form-input>
          </b-form-group>
        </b-form-group>

        <hr />
        <b-form-group
          label="เวลา และปฏิทินนัดหมาย"
          label-cols-lg="3"
          label-size="lg"
          label-class=" pt-0"
        >
          <b-form-group
            label="เวลาเปิด:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <Datetime
              class="col p-0"
              type="time"
              v-model="dateTimeOpening"
              input-class="form-control my-0"
              format="HH:mm"
              value-zone="UTC+7"
              :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
              :hour-step="1"
              :minute-step="30"
              auto
            >
            </Datetime>
          </b-form-group>
          <b-form-group
            label="เวลาปิด:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <Datetime
              class="col p-0"
              type="time"
              v-model="dateTimeClosing"
              input-class="form-control my-0"
              format="HH:mm"
              value-zone="UTC+7"
              :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
              :hour-step="1"
              :minute-step="30"
              auto
            >
            </Datetime>
          </b-form-group>

          <b-form-group
            label="ระยะเวลาในตารางนัด:"
            label-cols-sm="3"
            label-align-sm="right"
          >
            <b-input-group append="นาที">
              <b-form-select
                v-model="$v.period.$model"
                :options="periodOptions"
                :class="{ 'is-invalid': $v.period.$error }"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- กรุณาเลือกระยะตารางนัด --</b-form-select-option
                  ></template
                >
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-form-group>

        <hr />
        <b-form-group
          label="ค่ามือแพทย์ DF"
          label-cols-lg="3"
          label-size="lg"
          label-class=" pt-0"
        >
          <b-form-group
            label="ค่า DF มาตรฐาน:"
            label-cols-sm="3"
            label-align-sm="right"
            description="ค่ามาตรฐาน ใช้เฉพาะสร้างรายการรักษาใหม่เท่านั้น รายการรักษาเดิมที่สร้างไว้จะไม่เปลี่ยนแปลง"
          >
            <b-form-input
              v-model="$v.defaultDF.$model"
              number
              :class="{ 'is-invalid': $v.defaultDF.$error }"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="ค่า DF จัดฟัน:"
            label-cols-sm="3"
            label-align-sm="right"
            description="ค่ามาตรฐาน ใช้เฉพาะสร้างแพ็กเกจจัดฟันใหม่เท่านั้น แพ็กเกจเดิมที่สร้างไว้จะไม่เปลี่ยนแปลง"
          >
            <b-form-input
              v-model="$v.defaultOrthoDF.$model"
              number
              :class="{ 'is-invalid': $v.defaultOrthoDF.$error }"
            ></b-form-input>
          </b-form-group>
        </b-form-group>
        <div v-if="fileStoreExpiration">
          <hr />
          <b-form-group
            label="Storage"
            label-cols-lg="3"
            label-size="lg"
            label-class=" pt-0"
          >
            <b-form-group
              label="วันหมดอายุ:"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                disabled
                v-model="fileStoreExpiration"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="ข้อมูลที่เก็บได้:"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input disabled v-model="fileStoreQuota"></b-form-input>
            </b-form-group>
            <b-form-group
              label="ข้อมูลที่ใช้:"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input disabled v-model="fileStoreUsage"></b-form-input>
            </b-form-group>
          </b-form-group>
        </div>

        <hr />
        <b-button variant="primary" class="float-right" @click="saveConfig">
          บันทึก
        </b-button>
        <b-button
          variant="danger"
          class="float-right mr-2"
          @click="resetConfig"
        >
          ยกเลิก
        </b-button>
      </b-card>
      <Dialog ref="Dialog"></Dialog>
      <Loading v-if="isLoading"></Loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

import { Datetime } from "vue-datetime";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import UploadAvatarForm from "@/components/form/UploadAvatar";
import bytesToSize from "@/utils/bytesToSize";

import moment from "moment";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  maxLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

const digits = number =>
  helpers.withParams(
    { type: "digits", value: number },
    value => !helpers.req(value) || helpers.len(value) == number
  );

export default {
  name: "Setting",
  components: {
    Dialog,
    Loading,
    Datetime,
    UploadAvatarForm,
  },
  data() {
    return {
      isLoading: false,
      clinic: {
        code: "",
        name: "",
        nameEn: "",
        detail: "",
        contentId: null,
      },
      defaultContentId: null,
      reqExtDocItems: [],
      appointment: null,
      readOnly: false,
      branchTitle: "",
      address: "",
      branchTitleEn: "",
      addressEn: "",
      telNumber: "",
      facebook: "",
      lineID: "",
      website: "",
      logo: null,
      dateTimeOpening: null,
      dateTimeClosing: null,
      period: null,
      roomNumber: null,
      defaultDF: 50,
      defaultOrthoDF: 50,
      fileStoreExpiration: "",
      fileStoreQuota: "",
      fileStoreUsage: "",
      hcode: null,
      isFHIRAccessEnabled: false,
    };
  },
  validations: {
    period: { required },
    defaultDF: { required },
    defaultOrthoDF: { required },
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getAppointmentDurationList: "moduleMaster/getAppointmentDurationList",
    }),
    duration() {
      return moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
    },
    openingTime() {
      return this.dateTimeOpening ? this.dateTimeOpening : "09:00";
    },
    closingTime() {
      return this.dateTimeClosing ? this.dateTimeClosing : "20:00";
    },
    periodOptions() {
      return this.getAppointmentDurationList.map(o => ({
        value: o.code,
        text: o.label,
      }));
    },
  },
  methods: {
    ...mapActions({
      updateConfig: "moduleBranch/updateBranchConfig",
      fetchBranchByUrl: "moduleBranch/fetchBranchByUrl",
    }),
    init() {
      this.getClinic();
    },
    resetConfig() {
      this.branchTitle = this.getBranchInfo.name || "";
      this.address = this.getBranchInfo.address || "";
      this.branchTitleEn = this.getBranchInfo.nameEn || "";
      this.addressEn = this.getBranchInfo.addressEn || "";
      this.telNumber = this.getBranchInfo.tel || "";
      this.facebook = this.getBranchInfo.facebook || "";
      this.lineID = this.getBranchInfo.line || "";
      this.website = this.getBranchInfo.website || "";
      this.hcode = this.getBranchInfo.hospitalCode || null;
      this.logo = this.getBranchInfo.logo || null;
      this.dateTimeOpening = this.getBranchInfo.confOpeningTime;
      this.dateTimeClosing = this.getBranchInfo.confClosingTime;
      this.period = this.getBranchInfo.confApptDuration;
      this.defaultDF = this.getBranchInfo.df ?? 50;
      this.defaultOrthoDF = this.getBranchInfo.dfPackage ?? 50;
      this.fileStoreExpiration = this.getBranchInfo.fileStoreExpiration
        ? moment(this.getBranchInfo.fileStoreExpiration)
            .locale("th")
            .format("LL")
        : "";
      this.fileStoreQuota = bytesToSize(this.getBranchInfo.fileStoreQuota);
      this.fileStoreUsage = bytesToSize(this.getBranchInfo.fileStoreUsage);

      this.isFHIRAccessEnabled = this.getBranchInfo.isFHIRAccessEnabled;

      this.clinic = Object.assign(
        {},
        {
          code: "",
          name: "",
          nameEn: "",
          detail: "",
          contentId: null,
        }
      );
    },
    saveConfig() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var configData = {
        name: this.branchTitle,
        address: this.address,
        nameEn: this.branchTitleEn,
        addressEn: this.addressEn,
        tel: this.telNumber,
        facebook: this.facebook,
        line: this.lineID,
        website: this.website,
        confOpeningTime: this.formatTime(this.dateTimeOpening),
        confClosingTime: this.formatTime(this.dateTimeClosing),
        confApptDuration: this.period,
        df: this.defaultDF,
        dfPackage: this.defaultOrthoDF,
        id: this.getBranchInfo.id,
        isFHIRAccessEnabled: this.isFHIRAccessEnabled,
      };

      this.isLoading = true;
      this.updateConfig({
        data: configData,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          if (res.status == 200) {
            this.showDialogToast("success", `อัพเดตข้อมูลติดต่อสาขาสำเร็จ`);
          }
        })
        .catch(res => {
          this.showDialogToast("danger", `ไม่สามารถอัพเดตข้อมูลติดต่อสาขาได้`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeFHIR(e) {
      console.log(e);
      this.resetConfig();
      this.isFHIRAccessEnabled = e;
      var configData = {
        name: this.branchTitle,
        address: this.address,
        nameEn: this.branchTitleEn,
        addressEn: this.addressEn,
        tel: this.telNumber,
        facebook: this.facebook,
        line: this.lineID,
        website: this.website,
        confOpeningTime: this.formatTime(this.dateTimeOpening),
        confClosingTime: this.formatTime(this.dateTimeClosing),
        confApptDuration: this.period,
        df: this.defaultDF,
        dfPackage: this.defaultOrthoDF,
        id: this.getBranchInfo.id,
        isFHIRAccessEnabled: this.isFHIRAccessEnabled,
      };

      this.isLoading = true;
      this.updateConfig({
        data: configData,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          if (res.status == 200) {
            this.showDialogToast("success", `อัพเดตข้อมูลติดต่อสาขาสำเร็จ`);
          }
        })
        .catch(res => {
          this.showDialogToast("danger", `ไม่สามารถอัพเดตข้อมูลติดต่อสาขาได้`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    uploadAvatarCallback(state, contentId) {
      switch (state) {
        case "uploading":
          this.isLoading = true;
          break;
        case "callback":
          this.isLoading = false;
          this.checkPatient();
          break;
        case "delete":
          this.clinic.logo = contentId || null;
          break;
        case "reset":
          this.clinic.logo = contentId;
          break;
        case "success":
          this.isLoading = false;
          this.clinic.logo = contentId;
          this.checkPatient();
          break;
        case "fail":
          this.isLoading = false;
          this.showDialogToast("danger", `ไม่สามารถอัพโหลดรูปโปรไฟล์ได้`);
          this.checkPatient();
          break;
        default:
      }
    },
    resetForm() {
      this.$refs.UploadAvatar.reset("");
      this.setDefultClinic();
      this.$v.$reset();
    },
    saveSetting() {
      this.$refs.UploadAvatar.submit();
    },
    async checkPatient() {
      this.saveCustomer();
    },
    saveCustomer() {
      this.$refs.Dialog.showAlertConfirm(
        "แก้ไขข้อมูลคลีนิค ?",
        "คุณต้องการแก้ไข รายละเอียดข้อมูลคลีนิค"
      ).then(res => {
        if (res.isConfirmed) {
          var clinicUrl = this.$route.params.clinicUrl;
          var branchUrl = this.$route.params.branchUrl;
          axios
            .put(`/api/clinic/${clinicUrl}`, this.clinic)
            .then(res => {
              if (res.status == "200") {
                this.fetchBranchByUrl({
                  clinicUrl: this.$route.params.clinicUrl,
                  branchUrl: this.$route.params.branchUrl,
                });
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
    },
    getClinic() {
      var clinicUrl = this.$route.params.clinicUrl;
      axios.get(`/api/clinic/${clinicUrl}`).then(res => {
        this.clinic = Object.assign({}, res.data);
        this.defaultContentId = this.clinic.logo;
      });
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    formatTime(e) {
      if (e.length <= 5) return e;
      return moment(e).format("HH:mm");
    },
  },
  mounted() {
    this.resetConfig();
  },
  updated() {},
  beforeDestroy() {
    //เช็คเว่า edited?
  },
  destroyed() {},
};
</script>

<style scoped></style>
